import Vue from "vue";
import Vuex from "vuex";
import {SyncClient} from "twilio-sync";

import router from "../router";
// import {SyncClient} from "twilio-sync";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {faCircleMinus} from "@fortawesome/free-solid-svg-icons/faCircleMinus";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";

library.add(faCirclePlus);
library.add(faCircleMinus);
library.add(faBook);

Vue.use(Vuex);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

export default new Vuex.Store({
	state: {
		vm_unresponded_count: 0,
		version: false,
		version_on_client: "2024.2.1", // compare to /application/config/config.php version
		environment: "",

		activeRequestId: false,
		activePendingId: false,
		showFacilityModal: false,

		api: "http://API_PATH",

		//
		destination: "/conferences", //"/phone/logs",

		loggedIn: false,

		user: {
			email: "",
			facilities: []
		},

		counter: 0,

		ajaxLoader: false,

		pendingCalls: [],

		pendingAjaxing: false,

		allUsers: [],

		colors: [
			{ color: "red" },
			{ color: "green" },
			{ color: "orange" },
			{ color: "pink" },
			{ color: "purple" }

			// {color:'indigo'},
			// {color:'blue'},
			// {color:'cyan'},
			// {color:'teal'},
			// {color:'light-green'},
			//
			// {color:'light-blue'},
			// {color:'lime'},
			// {color:'amber'},
			// {color:'yellow'},
			// {color:'deep-purple'},
			//
			// {color:'deep-orange'},
			// {color:'brown'},
			// {color:'grey'},
			// {color:'blue-grey'},
			// {color:'black'}
		],

		globalModuleParams: {
			show: false,
			title: "Title",
			body: "<p>body</p>",
			closeMethod: function() {
				console.log("closeMethod");
			},
			okMethod: function() {
				console.log("okMethod");
			}
		},
		twilioSessions: [],

	},
	getters: {
		logoTrans(state) {
			if (state.user.facilities) {
				let firstFacility = state.user.facilities.find(fa => {
					return fa.styles;
				});
				//alert(firstFacility.id);

				if (firstFacility) {
					return firstFacility.styles.logo_64;
				} else {
					return "/static/rtnow-logo-greyscale.png";
				}
			}

			return "/static/rtnow-logo-greyscale.png";
		},

		// use in components as this.$store.getters.loggedIn;
		// or use mapGetters
		// or use mapGetters
		globalModuleParams: state => {
			return state.globalModuleParams;
		},

		userCanSee(state) {
			let canSee = {
				assessment: false,
				pending_calls: false
			};

			if (state.user && state.user.facilities) {
				canSee.assessment = !!state.user.facilities.find(f => {
					return f.rtnow_feature === true && f.rt === true;
				});

				canSee.pending_calls = !!state.user.facilities.find(f => {
					return f.rtnow_feature === true && f.rt === true;
				});
			}

			return canSee;
		},

		colors: state => {
			return state.colors;
		},

		loggedIn: state => {
			return state.loggedIn;
		},

		destination: state => {
			return state.destination;
		},

		ajaxLoader: state => {
			return state.ajaxLoader;
		},

		pendingCalls: state => {
			return state.pendingCalls;
		},

		// eslint-disable-next-line no-unused-vars
		selfUser: state => {
			//return false;
			return state.user;
		},

		allUsers: state => {
			let users = [];

			let i = 0;

			state.allUsers.forEach(user => {
				if (i === 20) {
					i = 0;
				}

				user.color = state.colors[i].color;

				users.push(user);

				i++;
			});

			return users;
		},
		getTwilioSessions(state) {
			return state.twilioSessions;
		}
	},

	mutations: {
		// use in components as this.$store.commit('increment');
		// or use mapMutations
		increment: state => {
			state.counter++;
		},

		decrement: state => {
			state.counter--;
		},

		updateActivePendingId: (state, payload) => {
			state.activePendingId = payload;
		},
		updateActiveRequestId: (state, payload) => {
			state.activeRequestId = payload;
		},

		//changeDestination: (state, payload) => { },

		afterLogin: (state, payload) => {
			state.loggedIn = true;

			state.user.email = payload.email;
		},

		afterLogout: state => {
			state.loggedIn = false;
		},

		setSelfColor: (state, payload) => {
			state.user.color = payload;
		},

		setAjaxLoader: (state, payload) => {
			state.ajaxLoader = payload;
		},

		setSelfName: (state, payload) => {
			state.user.last_name = payload.last_name;
			state.user.first_name = payload.first_name;
		},

		setVM_unresponded_count: (state, payload) => {
			state.vm_unresponded_count = payload;
		},

		setPendingAjaxing: (state, payload) => {
			state.pendingAjaxing = payload.bool;
		},
		addToTwilioSessions(state, payload) {
			state.twilioSessions.push(payload)
		},
		deleteFromTwilioSessions(state, index) {
			state.twilioSessions.splice(index, 1)
		},
		setPendingCalls(state, payload) {
			state.pendingCalls = payload
		}
	},

	actions: {
		globalModule: (context, payload) => {
			console.log(payload);
		},

		globalAlert: (context, payload) => {
			console.log(payload);

			context.state.globalModuleParams.title = payload.title;
			context.state.globalModuleParams.body = payload.body;
			context.state.globalModuleParams.buttons = [
				{
					text: "OK",
					method: () => {
						context.state.globalModuleParams.okMethod();
						context.state.globalModuleParams.show = false;
					}
				}
			];

			context.state.globalModuleParams.close = () => {
				context.state.globalModuleParams.show = false;
			};

			context.state.globalModuleParams.show = true;
		},

		getSelfUser: context => {
			//if(context.state.loggedIn){

			//alert('getSelfUser loggedIn');

			// eslint-disable-next-line no-undef
			$.ajax({
				dataType: "json",
				url: "/api/user/self",
				//data: payload,
				success: json => {
					console.log("json.user", json.user);
					if (json.user) {
						context.state.user = json.user;
					}

					context.state.vm_unresponded_count = json.vm_unresponded_count;
					context.state.version = json.version;
					context.state.environment = json.environment;

					// add server environment to body

					//var element = document.getElementById("app");
					//element.classList.add("environment-" + json.environment);
				}
			});

			//}

			//else {
			//	console.log('You must be logged in to api/user/self');
			//}
		},

		setSelfColor: (context, payload) => {
			//context.state.user.color = payload;

			context.commit("setSelfColor", payload);
		},

		setSelfName: (context, payload) => {
			//context.state.user.color = payload;

			context.commit("setSelfName", payload);
		},

		getPendingCalls: context => {
			console.log('calling get pending calls');
			//alert(!!context.state.user.rt_facilities.length);

			//console.log("attempting getPendingCalls()");
			// console.log(
			// 	"context.state.user.rt_facilities",
			// 	!!context.state.user.rt_facilities.length
			// );
			// console.log("context.state.user.rtnow_rt", context.state.user.rtnow_rt);
			// console.log(
			// 	"context.getters.userCanSee.pending_calls",
			// 	context.getters.userCanSee.pending_calls
			// );

			// if (context.state.loggedIn) {
				console.log("attempting /api/conference/pending ");
				//context.state.pendingAjaxing = true;

				context.commit("setPendingAjaxing", { bool: true });

				// eslint-disable-next-line no-undef
				$.ajax({
					dataType: "json",
					url: "/api/conference/pending",
					//data: payload,
					success: json => {
						context.state.pendingCalls = json.pending_calls;
						context.state.version = json.version;
						context.state.environment = json.environment;

						//context.state.pendingAjaxing = false;

						context.commit("setPendingAjaxing", { bool: false });

						// console.log(
						//   "context.state.pendingAjaxing: " + context.state.pendingAjaxing
						// );
					}
				});
			// } else {
			// 	console.log("You must be logged in to see pending conferences");
			// }
		},

		getAllUsers: context => {
			if (context.state.loggedIn) {
				// eslint-disable-next-line no-undef
				$.ajax({
					dataType: "json",
					url: "/api/user/get",
					//data: payload,
					success: json => {
						context.state.allUsers = json.users;
						context.state.version = json.version;
						context.state.environment = json.environment;
					}
				});
			} else {
				console.log("You must be logged in to see pending conferences");
			}
		},

		//actions can take `context` which contains things like `commit` (for calling mutations)
		// or you can limit `context` to just ({commit}) if you are only needing that piece of the `context`

		setDestination: (context, payload) => {
			context.state.destination = payload;
		},

		// used in component as this.$store.dispatch('logout')
		// or use mapActions
		logout: context => {
			//alert('logout action');

			// eslint-disable-next-line no-undef
			$.ajax({
				dataType: "json",
				url: "/api/authentication/logout",
				//data: payload,
				success: function(json) {
					console.log("logOut json", json);

					var token = localStorage.getItem('twilioSyncAccessToken');

					const syncClient = new SyncClient(token, { loglevel: 'debug' });

					// on log out disconnect from websocket
					syncClient.document(localStorage.getItem('documentUniqueName')).then(function(doc) {
						doc.removeDocument().then(function() {
							console.log('Document removed.');
						});
					});

					this.pending_calls = []

					localStorage.removeItem('twilioSyncAccessToken');
					localStorage.removeItem('documentUniqueName');

					context.commit("afterLogout");
				}
			});

			// if using logout: ({commit})
			// just call commit('afterLogout');
		},

		toggleFacilityModal: (context, payload) => {
			context.state.showFacilityModal = payload;
		},

		// method that connects to twilio sync/websocket
		connectToTwilioSync(context) {
			var token = localStorage.getItem('twilioSyncAccessToken');

			const syncClient = new SyncClient(token, { loglevel: 'debug' });

			// renew the token
			syncClient.on('tokenAboutToExpire', function() {
				// eslint-disable-next-line no-undef
				$.ajax({
					method: "GET",
					dataType: "json",
					url: "/api/authentication/getNewSyncAccessToken",
					success: async json => {
						localStorage.setItem('twilioSyncAccessToken', json.twilioAccessToken);
					},
				});
				syncClient.updateToken(localStorage.getItem('twilioSyncAccessToken'));
			});

			// renew the token
			syncClient.on('tokenExpired', function() {
				// eslint-disable-next-line no-undef
				$.ajax({
					method: "GET",
					dataType: "json",
					url: "/api/authentication/getNewSyncAccessToken",
					success: async json => {
						localStorage.setItem('twilioSyncAccessToken', json.twilioAccessToken);
					},
				});
				syncClient.updateToken(localStorage.getItem('twilioSyncAccessToken'));
			});

			// if the error comes back as access token error, try again
			syncClient.on('connectionError', (connectionError) => {
				console.error('Connection was interrupted:', connectionError.httpStatusCode);
				console.error('Connection was interrupted:', connectionError.errorCode);
				if(connectionError.httpStatusCode === 401) {
					// eslint-disable-next-line no-undef
					$.ajax({
						method: "GET",
						dataType: "json",
						url: "/api/authentication/getNewSyncAccessToken",
						success: async json => {
							localStorage.setItem('twilioSyncAccessToken', json.twilioAccessToken);

							await context.dispatch('connectToTwilioSync');
						},
					});
				}
				console.error('Connection was interrupted:', connectionError.message);
				console.error('Is terminal:', connectionError.terminal);
			});

			// Open a Document by unique name and update its data
			syncClient.document(localStorage.getItem('documentUniqueName'))
				.then(function(document) {
					// if the document created on the back end expired, but the front end created a new one without expiration date, log the user out and let them login again.
					// not optimal but did it in this way because front end automatically creates new document if the document doesnt exists
					if(!document.dateExpires){
						context.dispatch('logout');
					} else {
						// Listen to updates on the Document
						console.log('document name 1: ', document);
						// if there is value when connected, process it
						if(document.data) {
							console.log('Document existing event.', document.data);
							if(document.data.actionCode === 'callFromIpad') {
								context.commit("setPendingCalls", document.data.pending_calls);
							}
						}
						document.on('updated', function(event) {
							console.log('Received Document update event. New data:', event.data);

							if (event.data.actionCode === 'callFromIpad') {
								const payloadStatus = event.data.status;
								const newCalls = event.data.pending_calls;

								// Remove all calls with the same status from context.state.pendingCalls
								let remainingCalls = [];
								context.getters.pendingCalls.forEach((call) => {
									if(call.status !== payloadStatus) {
										console.log(call);
										remainingCalls.push(call)
									}
								})

								// Merge new calls with the existing pendingCalls
								let callsArray = [
									...newCalls,
									...remainingCalls,
								];

								context.commit('setPendingCalls', callsArray);
							}
						});
					}

					// // Update the Document data
					// var newData = { temperature: 23 };
					// return document.set(newData);
				})
			// .then(function(updateResult) {
			// 	console.log('The Document was successfully updated', updateResult)
			// })

		},

		login: (context, payload) => {
			//console.log("login payload", payload);

			context.state.ajaxLoader = true;

			// eslint-disable-next-line no-undef
			$.ajax({
				method: "POST",
				dataType: "json",
				url: "/api/authentication/login",
				data: payload,
				success: async json => {
					context.state.ajaxLoader = false;

					console.log("login json", json);

					context.state.user = json.user;
					context.state.version = json.version;
					context.state.environment = json.environment;

					// save the document unique name and access token to local storage
					localStorage.setItem('documentUniqueName', json.websocketData.unique_name);
					localStorage.setItem('twilioSyncAccessToken', json.websocketData.twilioAccessToken);

					// connect to twilio sync on login
					await context.dispatch('connectToTwilioSync');

					await context.dispatch('getPendingCalls');

					if (json.is_authenticated) {
						context.commit("afterLogin", payload);
					} else {
						//alert('Try again!');

						context.dispatch("globalAlert", {
							title: "Login Failed",
							body: "Try again."
						});
					}
				},
				error: function() {
					// set loggedIn to false
					context.commit("afterLogout");
				}
			});
		},

		// universal ajax method
		ajax: (context, payload) => {
			//console.log("ajax payload", payload);

			context.state.ajaxLoader = true;

			// remove any trailing slash
			payload.url = payload.url.replace(/\/$/, ""); //// Match a forward slash / at the end of the string

			// eslint-disable-next-line no-undef
			$.ajax({
				method: "POST",
				dataType: "json",

				url: "/api" + payload.url,
				data: payload.data,
				success: json => {
					console.log("ajax() json", json);

					context.state.ajaxLoader = false;
					context.state.version = json.version;
					if (json.version !== context.state.version_on_client) {
						let sure = confirm(
							"You are using an older version (" +
								context.state.version_on_client +
								") of this app.  Please refresh your browser for version " +
								json.version +
								"."
						);
						if (sure) {
							location.reload();
						}
					}
					context.state.environment = json.environment;

					if (json.validation_errors && payload.invalid) {
						//alert('requires validation');

						let val_errs = "";
						Object.keys(json.validation_errors).forEach(function(key) {
							// key: the name of the object key
							// index: the ordinal position of the key within the object
							val_errs +=
								'<span class="text-white badge badge-pill badge-danger">' +
								key +
								"</span> ";
							val_errs += json.validation_errors[key] + "<br>";
						});

						context.dispatch("globalAlert", {
							title: "Correct invalid or missing fields",
							body: val_errs
						});

						payload.invalid(json, context);
					} else if (json.error) {
						context.dispatch("globalAlert", {
							title: "Error Encountered",
							body: json.error
						});
						//alert();
					} else {
						payload.success(json, context);
					}

					//if is_authenticated
					if (json.is_authenticated) {
						context.commit("afterLogin", payload);
					} else {
						//alert('You need to log in.');
						context.commit("afterLogout");

						//window.location.hash = '/login';
						if (payload.pushLogin || typeof payload.pushLogin == "undefined") {
							router.push("/login");
						}
						//router.push('/login');
					}
				},
				error: function(msg) {
					context.state.ajaxLoader = false;

					//alert('ajax error calling /api' +payload.url+ ': view console.');

					context.dispatch("globalAlert", {
						title: "API Error",
						body: "Calling /api" + payload.url + ": view console."
					});

					console.warn(msg);
				}
			});
		}
	},
	modules: {}
});
